import React, { useState } from 'react';
import { SearchState, IntegratedFiltering, EditingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, Toolbar, TableColumnResizing, SearchPanel, VirtualTable, TableEditRow, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import styled from "styled-components";
import { Container, Row, Col, Form } from 'react-bootstrap';
import clienteAxios from '../../../../config/axios';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { format } from "date-fns";


const Info = styled.div`
    
`
const P = styled.p`
    text-align: justify;
    color:#000;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    color:#1D2146;
    font-size: 1rem;
    font-weight: bold;
`
const getRowId = row => row.id;

const Quotes = ({ location }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    const token = useSelector(state => state.auth.token)
    const role = useSelector(state => state.auth.role)

    const url = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(url);
    const id = urlParams.get('id')

    const [spaceData, setSpaceData] = useState(null)
    const [paymentsData, setPaymentsData] = useState([])
    const [cuotesQtyExpired, setCuotesQtyExpired] = useState(0)

    React.useEffect(() => {

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.get(`/api/space/byClient`, config)
            .then((res) => {
                // console.log(res.data)
                setSpaceData(res.data)
            })
            .catch(error => {
                // console.log(error)
            })

        clienteAxios.get(`/api/payment`, config)
            .then((res) => {
                // console.log(res.data)
                let payments = []
                let qtyCuotesExpired = 0
                for (let i = 0; i < res.data.length; i++) {
                    let payment = res.data[i]
                    if (payment.paymentStatus === 'Vencido') {
                        qtyCuotesExpired = qtyCuotesExpired + 1
                    }
                    payments.push({
                        fecha: payment.paymentDate ? payment.paymentDate.split("T")[0] : "",
                        concepto: payment.concept,
                        vencimiento: payment.expiration.split("T")[0],
                        retrazo: payment.delay.toString() + " días",
                        apagar: payment.price,
                        pago: payment.paymentAmount ? payment.paymentAmount : 0,
                        estado: payment.paymentStatus,
                        id: payment._id,
                    })
                }
                setCuotesQtyExpired(qtyCuotesExpired)
                setPaymentsData(payments)
            })
            .catch(error => {
                // console.log(error)
            })

    }, [])

    const getDataTableAfterUpdate = (data) => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/payment/space/${id}`, config)
            .then((res) => {
                // console.log(res.data)
                let payments = []
                let qtyCuotesExpired = 0
                for (let i = 0; i < res.data.length; i++) {
                    let payment = res.data[i]
                    if (payment.paymentStatus === 'Vencido') {
                        qtyCuotesExpired = qtyCuotesExpired + 1
                    }
                    payments.push({
                        fecha: payment.paymentDate ? payment.paymentDate.split("T")[0] : "",
                        concepto: payment.concept,
                        vencimiento: payment.expiration.split("T")[0],
                        retrazo: payment.delay.toString() + " días",
                        apagar: payment.price,
                        pago: payment.paymentAmount ? payment.paymentAmount : 0,
                        estado: payment.paymentStatus,
                        id: payment._id,
                    })
                }
                setCuotesQtyExpired(qtyCuotesExpired)
                setPaymentsData(payments)
            })
            .catch(error => {
                // console.log(error)
            })
    }


    const [columns] = React.useState([
        { name: 'fecha', title: 'Fecha de pago' },
        { name: 'concepto', title: 'Concepto' },
        { name: 'vencimiento', title: 'Vencimiento' },
        { name: 'retrazo', title: 'Retraso' },
        { name: 'apagar', title: 'A pagar ($)' },
        { name: 'pago', title: 'Pago' },
        { name: 'estado', title: 'Estado' },
    ]);

    const [tableColumnExtensions] = useState([
        { columnName: 'fecha', width: 250 },
        { columnName: 'concepto', width: 250 },
        { columnName: 'vencimiento', width: 250 },
        { columnName: 'retrazo', width: 250 },
        { columnName: 'apagar', width: 250 },
        { columnName: 'pago', width: 250 },
        { columnName: 'estado', width: 250 },
    ]);

    const [editingStateColumnExtensions] = useState([
        { columnName: 'fecha', editingEnabled: true },
        { columnName: 'concepto', editingEnabled: false },
        { columnName: 'vencimiento', editingEnabled: false },
        { columnName: 'retrazo', editingEnabled: false },
        { columnName: 'apagar', editingEnabled: false },
        { columnName: 'pago', editingEnabled: true },
        { columnName: 'estado', editingEnabled: false },
    ]);

    const [lookupColumns] = useState(["fecha"]);

    const HighlightedCell = ({ value, style, ...restProps }) => (
        <Table.Cell {...restProps}>
            <span style={{ color: value === "Vencido" ? '#DD3434' : "#039487" }}>
                {value}
            </span>
        </Table.Cell>
    );

    const Cell = (props) => {
        const { column } = props;
        if (column.name === 'estado') {
            return <HighlightedCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };


    const LookupEditor = ({ value, onValueChange }) => {
        return (
            <Form.Control
                id="date"
                type="date"
                value={value}
                onChange={e => {
                    onValueChange(e.target.value);
                }}
                InputLabelProps={{
                    shrink: true
                }}
            />
        );
    };

    const LookupFormatter = props => (
        <DataTypeProvider
            editorComponent={params => <LookupEditor {...params} />}
            {...props}
        />
    );


    const commitChanges = async ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = paymentsData.length > 0 ? paymentsData[paymentsData.length - 1].id + 1 : 0;
            changedRows = [
                ...paymentsData,
                ...added.map((row, index) => ({
                    id: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            // console.log(changed)

            let promise = new Promise((resolve, reject) => {

                const config = {
                    headers: { 'Authorization': "Bearer " + token }
                };

                let idRow;
                let body;
                for (var key1 in changed) {
                    // console.log(key1);
                    idRow = key1;
                    for (var key2 in changed[key1]) {
                        // console.log(key2);
                        body = {
                            paymentAmount: changed[key1].pago,
                            paymentDate: changed[key1].fecha,
                        }
                    }
                }

                // console.log(body)

                if (body.paymentAmount && body.paymentDate) {
                    clienteAxios.put(`/api/payment/${idRow}`, body, config)
                        .then((res) => {
                            // console.log(res.data)
                            changedRows = paymentsData.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
                            Toast.fire({ icon: 'success', title: 'Editado correctamente' })
                            resolve("done!")
                            getDataTableAfterUpdate()
                        })
                        .catch(error => {
                            Toast.fire({ icon: 'error', title: 'Error al editar' })
                            reject()
                        })
                } else {
                    changedRows = paymentsData.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
                    Swal.fire({ icon: 'error', title: 'Fecha y monto de pago requerido!' })
                    reject()
                }
            });

            let result = await promise; // wait until the promise resolves (*)
            console.log(result); // "done!"

        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = paymentsData.filter(row => !deletedSet.has(row.id));
        }
        setPaymentsData(changedRows);
    };

    const formatterPeso = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0
    })


    return (
        <React.Fragment>
            <Info className="mb-1">
                <Container>
                    <Row>
                        <Col className="px-0" lg={4}>
                            <div className="d-flex">
                                <H3 className="me-2">Cliente: </H3>
                                <P>{spaceData ? spaceData.client.name + " " + spaceData.client.lastname : ""}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Lote: </H3>
                                <P>{spaceData ? spaceData.lote : ""}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Mensura: </H3>
                                <P>{spaceData ? spaceData.mensura : ""}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Cuotas: </H3>
                                <P>{spaceData ? spaceData.cuotas : ""} en {spaceData ? spaceData.divisa : ""}</P>
                            </div>
                        </Col>
                        <Col className="px-0" lg={4}>
                            <div className="d-flex">
                                <H3 className="me-2">Barrio: </H3>
                                <P>{spaceData ? spaceData.barrio : ""}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Saldo total: </H3>
                                <P>{formatterPeso.format(spaceData ? spaceData.saldoTotal : "")}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Saldo deudor: </H3>
                                <P>{formatterPeso.format(spaceData ? spaceData.saldoALaFecha : "")}</P>
                            </div>
                            <div className="d-flex">
                                <H3 className="me-2">Pago total: </H3>
                                <P>{formatterPeso.format(spaceData ? spaceData.pagoTotal : "")}</P>
                            </div>
                        </Col>
                        <Col className="px-0" lg={4}>
                            <div className="d-flex">
                                <H3 className="me-2">Cuotas vencidas: </H3>
                                <P>{cuotesQtyExpired}</P>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Info>
            <Container>
                <Row>
                    <Col className="px-0" xs={12}><p><span>*</span>los días de retraso generan el interés detallado en el boleto de compraventa</p></Col>
                </Row>
            </Container>
            <div className="card">
                <Grid rows={paymentsData} columns={columns} getRowId={getRowId}>
                    <LookupFormatter for={lookupColumns} />
                    <SearchState />
                    <IntegratedFiltering />
                    <EditingState
                        onCommitChanges={commitChanges}
                        columnExtensions={editingStateColumnExtensions}
                    />
                    <Table />
                    <VirtualTable height={430} cellComponent={Cell} />
                    <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
                    <TableHeaderRow />
                    <Toolbar />
                    <SearchPanel />
                    <TableEditRow />
                    {role === 'superAdmin' && (
                        <TableEditColumn
                            showEditCommand
                        />
                    )}
                </Grid>
            </div>
        </React.Fragment>
    );
}

export default Quotes;